// import api from "@/api";

import axios from "@/axios";

const getters = {
    wheelSounds(state) {
        return state.wheelSounds;
    },
    predictionSounds(state) {
        return state.predictionSounds;
    },
};

const state = {
    wheelSounds: [],
    predictionSounds: [],
};

export const SET_WHEEL_SOUNDS = "setWheelSounds";
export const SET_PREDICTION_SOUNDS = "setPredictionSounds";

const mutations = {
    [SET_WHEEL_SOUNDS](state, payload) {
        state.wheelSounds = payload;
    },
    [SET_PREDICTION_SOUNDS](state, payload) {
        state.predictionSounds = payload;
    },
};

export const GET_WHEEL_SOUNDS = "getWheelSounds";
export const GET_PREDICTION_SOUNDS = "getPredictionSounds";
const actions = {
    [GET_WHEEL_SOUNDS](context) {
        return new Promise((resolve, reject) => {
            axios
                .post("/wheel.sounds")
                .then((response) => {
                    context.commit(SET_WHEEL_SOUNDS, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [GET_PREDICTION_SOUNDS](context) {
        return new Promise((resolve, reject) => {
            axios
                .post("/prediction.sounds")
                .then((response) => {
                    context.commit(SET_PREDICTION_SOUNDS, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};
