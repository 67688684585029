<template>
    <footer class="footer footer-transparent d-print-none">
        <div class="container-xl">
            <div class="row text-center align-items-center flex-row-reverse">
                <div class="col-lg-auto ms-lg-auto">
                    <ul class="list-inline list-inline-dots mb-0">
                        <!--                        <li class="list-inline-item">
                            <a href="./docs/index.html" class="link-secondary"
                                >Documentation</a
                            >
                        </li>-->
                        <li class="list-inline-item">
                            <a
                                href="https://t.me/streamer_fun"
                                target="_blank"
                                class="link-secondary"
                                rel="noopener"
                                ><i
                                    class="fa-brands fa-telegram me-1"
                                    style="color: #229ed9"
                                ></i
                                >Telegram</a
                            >
                        </li>
                        <li class="list-inline-item">
                            <a
                                href="https://www.donationalerts.com/r/stark_dev"
                                target="_blank"
                                class="link-secondary"
                                rel="noopener"
                            >
                                <!-- Download SVG icon from http://tabler-icons.io/i/heart -->
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon text-pink icon-filled icon-inline me-1"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                    />
                                    <path
                                        d="M19.5 12.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"
                                    /></svg
                                >Поддержать проект
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="row text-center align-items-center flex-row-reverse mt-2"
            >
                <div class="col-lg-auto ms-lg-auto">
                    <ul class="list-inline list-inline-dots mb-0">
                        <!--                        <li class="list-inline-item">
                            <a href="./docs/index.html" class="link-secondary"
                                >Documentation</a
                            >
                        </li>-->
                        <li class="list-inline-item">
                            <router-link
                                :to="{ name: 'personal' }"
                                class="link-secondary"
                                >Обработка персональных данных
                            </router-link>
                        </li>
                        <li class="list-inline-item">
                            <router-link
                                :to="{ name: 'terms' }"
                                class="link-secondary"
                                >Политика конфиденциальности</router-link
                            >
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-lg-auto mt-3 mt-lg-0">
                    <ul class="list-inline list-inline-dots mb-0">
                        <li class="list-inline-item">
                            Copyright &copy; 2023
                            <a href="/" class="link-secondary">streamer.fun</a>.
                            All rights reserved.
                        </li>
                        <li class="list-inline-item">
                            <a href="#" class="link-secondary" rel="noopener">
                                beta v0.2.17.1
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterLayout",
};
</script>

<style scoped></style>
