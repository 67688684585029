// import api from "@/api";

import axios from "@/axios";

const getters = {
    predictionStyles(state) {
        return state.styles;
    },
};

const state = {
    styles: [],
};

export const SET_PREDICTION_STYLES = "setPredictionStyles";
export const ADD_PREDICTION_STYLE = "addPredictionStyle";
export const MODIFY_PREDICTION_STYLE = "modifyPredictionStyle";

const mutations = {
    [SET_PREDICTION_STYLES](state, payload) {
        state.styles = payload;
    },
    [ADD_PREDICTION_STYLE](state, payload) {
        state.styles.push(payload);
    },
    [MODIFY_PREDICTION_STYLE](state, payload) {
        state.styles = state.styles.map((s) => {
            if (s.id === payload.id) s = payload;
            return s;
        });
    },
};

export const CREATE_PREDICTION_STYLE = "createPredictionStyle";
export const GET_PREDICTION_STYLES = "getPredictionStyles";
export const UPDATE_PREDICTION_STYLE = "updatePredictionStyle";

export const UPDATE_TWITCH_SCOPES = "updateTwitchScopes";

const actions = {
    [GET_PREDICTION_STYLES](context) {
        return new Promise((resolve, reject) => {
            axios
                .post("/prediction.styles")
                .then((response) => {
                    context.commit(SET_PREDICTION_STYLES, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [CREATE_PREDICTION_STYLE](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/prediction.styles.create", payload)
                .then((response) => {
                    context.commit(ADD_PREDICTION_STYLE, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [UPDATE_PREDICTION_STYLE](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/prediction.styles.update", payload)
                .then((response) => {
                    context.commit(MODIFY_PREDICTION_STYLE, response.data);
                    resolve(response);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
    [UPDATE_TWITCH_SCOPES](context, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/twitch.scopes.update", payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((data) => {
                    reject(data.response);
                });
        });
    },
};

export default {
    getters,
    state,
    mutations,
    actions,
};
